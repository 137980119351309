 <template>
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <!-- FINAL SAY CARD -->

      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          Final Say
        </h3>
      </div>

      <div class="card-body">
        <p>
          If you are still reading then congratulations for taking the time to
          go right through the white paper, most people don’t make it past the
          first page of any of them. AS stated at the beginning it is important
          not to over promise and set unachievable goals, as such everything
          laid out above is something that has been discussed with a high level
          team of developers who will be working on the project who believe it
          is all achievable. Though there are sure to be bumps in the road that
          is to be expected and no journey worth taking is to be easy.
        </p>
        <p>
          Revenue is extremely important to any project for the continuation of
          its development, as such the protocol is set up to be fun to use with
          some unique features that will also continue to bring in funds after
          the initial mints. This will always be the theme so that ultimately
          the project never stops growing and developing.
        </p>
        <p>
          Partnerships is also another area the project will lean on where
          needed with projects that are known to be trustworthy and where a
          mutually beneficial outcome can be achieved.
        </p>
      </div>

      <!-- FINAL SAY CARD -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  components: {},

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}
</style>
