 <template>
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <!-- BATTLE FEATURE CARD -->

      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          Battle Feature
        </h3>
      </div>

      <div class="card-body">
        <p>
          The "Battle Feature" in the "Mutant Wars" project offers exciting
          player-versus-player action. For the initial version, players will
          choose their NFTs and create a "Bounty" challenge. Battles will take
          place between teams of 1 to 5 NFTs, which will be kept hidden from
          potential opponents. If a rival accepts the challenge, they match the
          bounty, and the battle commences. The winner takes home 95% of the
          combined bounty, with the remaining 5% going towards future
          development.
        </p>

        <p>
          The first version of the game will feature a straightforward rarity
          and level-based battle system. Subsequent versions will add more
          complexity, drawing on the project's story narrative to introduce new
          elements such as elemental multipliers and alliance bonuses.
        </p>

        <p>
          In the "Battle the Bank" mode, players can attack the bank with their
          NFTs for a chance to win the jackpot or other prizes. The pay-outs
          will be determined by a randomizer and there will be a small fee to
          play each time, per NFT.
        </p>
      </div>

      <!-- BATTLE FEATURE CARD -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  components: {},

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}
</style>
