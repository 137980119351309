 <template>
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <!-- STAKING AND LEVELING CARD -->
      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          Staking + Leveling System and Mutant Coin
        </h3>
      </div>

      <div class="card-body">
        <p>
          Upon completion of the initial 10’000 mint staking and levelling will
          be enabled. A tried and tested UI and platform will be utilised for
          this that is attractive, intuitive and user friendly. The Staking is
          where you will be able to earn the Mutant coin, used to level up and
          make your Mutants more powerful for the impending battles ahead.
        </p>
        <p>
          Mutant coin will also be earnable by providing liquidity to third
          party DEX’s and staking your Liquidity Provider tokens, a tutorial
          will be provided for people who are unsure what this is when needed.
          Thus bringing in another earning potential to the protocol.
        </p>
        <blockquote>
          <p class="blockquote blockquote-secondary text-muted">
            <small>
              <b>Note:</b>
              <br />
              LP providing is not without risk, please ensure you are aware of
              them before partaking in this feature as it is at your own Risk.
            </small>
          </p>
        </blockquote>
      </div>
      <!-- STAKING AND LEVELING CARD -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  components: {},

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}
</style>
