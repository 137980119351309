

<template>
  <div class="row">
    <div class="col-md-12">
      <NotFoundCard :heightVisibleToFadeOut="0.25" />
    </div>
  </div>
</template>

<script>
import NotFoundCard from "@/pages/Home/NotFoundCard.vue";

export default {
  components: {
    NotFoundCard,
  },
};
</script>

<style lang="scss">
@import "../assets/css/mt.scss";
</style>

