 <template>
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <!-- NFT COLLECTION CARD -->

      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          Taking a Closer Look at
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars NFT Collection
        </h3>
      </div>

      <div class="card-body">
        <p>
          The scope and size of the NFT set is without question impressive. To
          elaborate: On completion of the mints there will be:
        </p>

        <div>
          <ul>
            <li class="title animatedTextOnHover animatedTextShadowOnHover">
              16 Base Characters
            </li>
            <li class="title animatedTextOnHover animatedTextShadowOnHover">
              Over 350 Traits
            </li>
            <li class="title animatedTextOnHover animatedTextShadowOnHover">
              10 Specials (1 of 1 NFTs)
            </li>
            <li class="title animatedTextOnHover animatedTextShadowOnHover">
              14000 NFTs in Total
            </li>
          </ul>
        </div>

        <div>
          <p class="title animatedTextOnHover animatedTextShadowOnHover">
            The NFT Mints will be splits as Follows
          </p>
          <p class="text-muted">(Explanation as to why will follow)</p>

          <ul class="title animatedTextOnHover animatedTextShadowOnHover">
            <li>Initial Mint</li>
            <ul>
              <li>10000 NFTs</li>
              <li>10 Bases</li>
              <li>8 Special</li>
            </ul>
          </ul>
          <ul class="title animatedTextOnHover animatedTextShadowOnHover">
            <li>Second Mint</li>
            <ul>
              <li>2000 NFTs</li>
              <li>3 Bases</li>
              <li>1 Special</li>
            </ul>
          </ul>
          <ul class="title animatedTextOnHover animatedTextShadowOnHover">
            <li>Third Mint</li>
            <ul>
              <li>2000 NFTs</li>
              <li>3 Bases</li>
              <li>1 Special</li>
            </ul>
          </ul>
        </div>

        <div>
          <p class="title animatedTextOnHover animatedTextShadowOnHover">
            Why 3 Mints?
          </p>
          <p>
            Minting in 3 consequitive stages fits in nicely with the narrative
            for the project of the ongoing battle for survival and dominance of
            earth. With each mint several new characters are introduced to the
            collection, opening up the possibility of different alliances and
            enemies. Again, this is a directional play for a story telling/comic
            book theme that may be taken in the future. On top of that it
            provides staggered income into the project for further development.
            Each mint will coincide with the release of a version of the PvP
            Battle feature.
          </p>
        </div>
      </div>

      <!-- NFT COLLECTION CARD -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  components: {},

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}
</style>
