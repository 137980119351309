<template>
  <div class="fade-in-out-container" ref="container">
    <img
      src="img/logo.jpg"
      :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }"
    />
  </div>
</template>

<script>
export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.4,
      description: "Height left visible to start fadeout",
    },
  },

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
img {
  display: block;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.05;
  transition: opacity 5s ease;
}
</style>
