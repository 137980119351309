<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header mb-2">
          <h5 class="card-category">Taking a Closer Look at</h5>
          <h3 class="card-title">The Mutants Wars NFT Collection</h3>
        </div>

        <div class="card-body">
          <p>
            The scope and size of the NFT set is without question impressive. To
            elaborate: On completion of the mints there will be:
          </p>

          <div class="typography-line">
            <span>Muted Text</span>
            <p class="text-muted">
              I will be the leader of a company that ends up being worth
              billions of dollars, because I got the answers...
            </p>
          </div>
          <div class="typography-line">
            <span>Primary Text</span>
            <p class="text-primary">
              I will be the leader of a company that ends up being worth
              billions of dollars, because I got the answers...
            </p>
          </div>
          <div class="typography-line">
            <span>Info Text</span>
            <p class="text-info">
              I will be the leader of a company that ends up being worth
              billions of dollars, because I got the answers...
            </p>
          </div>
          <div class="typography-line">
            <span>Success Text</span>
            <p class="text-success">
              I will be the leader of a company that ends up being worth
              billions of dollars, because I got the answers...
            </p>
          </div>
          <div class="typography-line">
            <span>Warning Text</span>
            <p class="text-warning">
              I will be the leader of a company that ends up being worth
              billions of dollars, because I got the answers...
            </p>
          </div>
          <div class="typography-line">
            <span>Danger Text</span>
            <p class="text-danger">
              I will be the leader of a company that ends up being worth
              billions of dollars, because I got the answers...
            </p>
          </div>
          <div class="typography-line">
            <h2>
              <span>Small Tag</span>
              Header with small subtitle
              <br />
              <small>Use "small" tag for the headers</small>
            </h2>
          </div>
          <div class="typography-line">
            <span>Lists</span>
            <div class="row">
              <div class="col-md-3">
                <h5>Unordered List</h5>
                <ul>
                  <li>List Item</li>
                  <li>List Item</li>
                  <li class="list-unstyled">
                    <ul>
                      <li>List Item</li>
                      <li>List Item</li>
                      <li>List Item</li>
                    </ul>
                  </li>
                  <li>List Item</li>
                </ul>
              </div>
              <div class="col-md-3">
                <h5>Ordered List</h5>
                <ol>
                  <li>List Item</li>
                  <li>List Item</li>
                  <li>List item</li>
                  <li>List Item</li>
                </ol>
              </div>
              <div class="col-md-3">
                <h5>Unstyled List</h5>
                <ul class="list-unstyled">
                  <li>List Item</li>
                  <li>List Item</li>
                  <li>List item</li>
                  <li>List Item</li>
                </ul>
              </div>
              <div class="col-md-3">
                <h5>Inline List</h5>
                <ul class="list-inline">
                  <li class="list-inline-item">List1</li>
                  <li class="list-inline-item">List2</li>
                  <li class="list-inline-item">List3</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="typography-line">
            <span>Code</span>
            <p>
              This is <code>.css-class-as-code</code>, an example of an inline
              code element. Wrap inline code within a
              <code> &lt;code&gt;...&lt;/code&gt;</code>tag.
            </p>
            <pre>1. #This is an example of preformatted text.<br/>2. #Here is another line of code</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
img {
  display: block;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.animated_gradient:hover {
  color: #19d1f1;
  background: -webkit-linear-gradient(
    left,
    $gradient-color-1,
    $gradient-color-2,
    $gradient-color-1
  );
  background-size: 1600px 200px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 9s;
  animation-name: animationgradienttitle;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
</style>
