<template>
  <!-- DISCLAIMER CARD -->
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          {{ siteName }}
        </h3>
      </div>

      <div class="card-body">
        <h1 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          {{ title }}
        </h1>

        <!-- <blockquote>
          <p class="blockquote blockquote-primary">
            'The preparations are going full steam ahead so that the Mutant Wars
            project will be ready when Shibarium launches. Stay tuned!'
            <br />
            <br />
            <small> - The Mutant Wars Team </small>
          </p>
        </blockquote> -->

        <!-- <ImgFadeInOut class="imgShadowOnHover" alt="Mutant Wars Logo" /> -->
      </div>
    </div>
  </div>
  <!-- DISCLAIMER CARD  -->
</template>

<script>
// import ImgFadeInOut from "@/components/ImgFadeInOut.vue";

export default {
  props: {
    title: {
      type: String,
      default: "Coming Soon",
      description: "Title of the card",
    },

    siteName: {
      type: String,
      default: "Site",
      description: "Site of the card",
    },

    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  // components: {
  //   ImgFadeInOut,
  // },

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}

h1 {
  font-size: 72px;
  display: block;
  width: 100%;
  padding-left: 10%;
  padding-right: auto;
  padding-top: 5%;
  padding-bottom: 10%;
}
</style>
