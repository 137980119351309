<template>
  <!-- INTRO CARD -->
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          Welcome to
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars Project on Shibarium
        </h3>
      </div>

      <ImgFadeInOut class="imgShadowOnHover" alt="Mutant Wars Logo" />

      <div class="card-body">
        <h2 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          What is "Mutant Wars"?
        </h2>

        <p>
          The "Mutant Wars" project revolves around an exceptional collection of
          NFTs that are designed to offer numerous opportunities for growth and
          expansion. The NFTs are set in a post-apocalyptic world where various
          humanoids, cyborgs, mutated apes, and zombies compete to become the
          dominant species. This narrative provides a wealth of creative avenues
          for development, in addition to being a visually striking and unique
          NFT set.
        </p>

        <blockquote>
          <p class="blockquote blockquote-primary">
            'The "Mutant Wars" project revolves around an exceptional collection
            of NFTs that are designed to offer numerous opportunities for growth
            and expansion. The NFTs are set in a post-apocalyptic world where
            various humanoids, cyborgs, mutated apes, and zombies compete to
            become the dominant species. This narrative provides a wealth of
            creative avenues for development, in addition to being a visually
            striking and unique NFT set.'
            <br />
            <br />
            <small> - Diggle, Project Owner </small>
          </p>
        </blockquote>
      </div>
    </div>
  </div>
  <!-- INTRO CARD  -->
</template>

<script>
import ImgFadeInOut from "@/components/ImgFadeInOut.vue";

export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  components: {
    ImgFadeInOut,
  },

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}
</style>
