<template>
  <div>
    <h1>NFT Battle Game</h1>

    <!-- Form for submitting a challenge -->
    <h2>Submit a challenge</h2>
    <form>
      <label for="challenge-player2">Player 2 Ethereum address:</label>
      <input type="text" id="challenge-player2" v-model="player2" required />

      <label for="challenge-team">Select up to 5 NFTs:</label>
      <div class="nft-list">
        <div v-for="nft in nfts" :key="nft.id" @click="toggleSelected(nft)">
          <img
            :src="nft.image"
            :alt="nft.name"
            :class="{ selected: nft.selected }"
          />
          <div class="nft-name">{{ nft.name }}</div>
          <div class="nft-stats">
            <div class="nft-stat">{{ nft.attack }} ATK</div>
            <div class="nft-stat">{{ nft.defense }} DEF</div>
            <div class="nft-stat">{{ nft.speed }} SPD</div>
          </div>
        </div>
      </div>

      <button @click.prevent="submitChallenge">Submit challenge</button>
    </form>

    <!-- List of user's NFTs -->
    <h2>Your NFTs</h2>
    <div class="nft-list">
      <div v-for="nft in nfts" :key="nft.id">
        <img :src="nft.image" :alt="nft.name" />
        <div class="nft-name">{{ nft.name }}</div>
        <div class="nft-stats">
          <div class="nft-stat">{{ nft.attack }} ATK</div>
          <div class="nft-stat">{{ nft.defense }} DEF</div>
          <div class="nft-stat">{{ nft.speed }} SPD</div>
        </div>
      </div>
    </div>

    <!-- Selected NFTs -->
    <h2>Selected NFTs</h2>
    <div class="nft-list">
      <div v-for="nft in selectedNfts" :key="nft.id">
        <img :src="nft.image" :alt="nft.name" />
        <div class="nft-name">{{ nft.name }}</div>
        <div class="nft-stats">
          <div class="nft-stat">{{ nft.attack }} ATK</div>
          <div class="nft-stat">{{ nft.defense }} DEF</div>
          <div class="nft-stat">{{ nft.speed }} SPD</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      player2: "",
      nfts: [
        {
          id: 1,
          name: "NFT 1",
          image: "https://via.placeholder.com/150",
          attack: 10,
          defense: 5,
          speed: 7,
          selected: false,
        },
        {
          id: 2,
          name: "NFT 2",
          image: "https://via.placeholder.com/150",
          attack: 7,
          defense: 8,
          speed: 6,
          selected: false,
        },
        {
          id: 3,
          name: "NFT 3",
          image: "https://example.com/nft3.png",
          stats: {
            power: 50,
            defense: 30,
            speed: 70,
          },
        },
        {
          id: 4,
          name: "NFT 4",
          image: "https://example.com/nft4.png",
          stats: {
            power: 80,
            defense: 20,
            speed: 60,
          },
        },
        {
          id: 5,
          name: "NFT 5",
          image: "https://example.com/nft5.png",
          stats: {
            power: 40,
            defense: 60,
            speed: 50,
          },
        },
      ],
      selectedNFTs: [], // to store the user's selected NFTs
    };
  },
  methods: {
    selectNFT(nft) {
      // check if the NFT is already selected
      const index = this.selectedNFTs.findIndex((item) => item.id === nft.id);
      if (index !== -1) {
        // if it's already selected, remove it from the selectedNFTs array
        this.selectedNFTs.splice(index, 1);
      } else {
        // if it's not already selected, add it to the selectedNFTs array
        this.selectedNFTs.push(nft);
      }
    },
  },
};
</script>

<style>
.nft-list {
  display: flex;
  flex-wrap: wrap;
}

.nft-item {
  width: 200px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.nft-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.nft-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.nft-stat {
  display: flex;
  align-items: center;
}

.nft-stat-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.nft-selected {
  border-color: green;
}
</style>
