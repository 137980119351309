<template>
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <!-- FINAL SAY CARD -->

      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          Final Say
        </h3>
      </div>

      <div class="card-body">
        <GameVisualization :team1="[1, 2]" :team2="[3, 5]" />
      </div>

      <!-- FINAL SAY CARD -->
    </div>
  </div>
</template>

<script>
import GameVisualization from "./GameVisualization.vue";

export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  components: { GameVisualization },

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}
</style>
