<template>
  <div class="row">
    <div class="col-md-12">
      <IntroCard :heightVisibleToFadeOut="0.25" />
      <NftCollectionCard :heightVisibleToFadeOut="0.275" />
      <ImgFancy :heightVisibleToFadeOut="0.4" />
      <BattleFeatureCard :heightVisibleToFadeOut="0.33" />
      <NamingPortalCard :heightVisibleToFadeOut="0.36" />
      <StakingAndLevelingCard :heightVisibleToFadeOut="0.3" />
      <DevRunningOrderCard :heightVisibleToFadeOut="0.25" />
      <FinalSayCard :heightVisibleToFadeOut="0.25" />
    </div>
  </div>
</template>


<script>
import IntroCard from "@/pages/Home/IntroCard.vue";
import NftCollectionCard from "@/pages/Home/NftCollectionCard.vue";
import BattleFeatureCard from "@/pages/Home/BattleFeatureCard.vue";
import NamingPortalCard from "@/pages/Home/NamingPortalCard.vue";
import StakingAndLevelingCard from "@/pages/Home/StakingAndLevelingCard.vue";
import DevRunningOrderCard from "@/pages/Home/DevRunningOrderCard.vue";
import FinalSayCard from "@/pages/Home/FinalSayCard.vue";

import ImgFancy from "@/components/ImgFancy.vue";

export default {
  components: {
    IntroCard,
    NftCollectionCard,
    BattleFeatureCard,
    NamingPortalCard,
    StakingAndLevelingCard,
    DevRunningOrderCard,
    FinalSayCard,
    ImgFancy,
  },
  props: {},
  data() {
    return {
      reveal: false,
    };
  },
};
</script>
<style lang="scss">
@import "../assets/css/mt.scss";
</style>
