<template>
  <div class="row">
    <div class="col-md-12">
      <DisclaimerCard :heightVisibleToFadeOut="0.25" />
    </div>
  </div>
</template>
<script>
import DisclaimerCard from "@/pages/Home/DisclaimerCard.vue";

export default {
  components: {
    DisclaimerCard,
  },
};
</script>

<style lang="scss">
@import "../assets/css/mt.scss";
</style>
