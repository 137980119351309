<template>
  <div class="row">
    <div class="col-md-12">
      <ComingSoonCard
        :siteName="siteName"
        :title="title"
        :heightVisibleToFadeOut="0.25"
      />
    </div>
  </div>
</template>

<script>
import ComingSoonCard from "@/pages/Home/ComingSoonCard.vue";
import { useRoute } from "vue-router";

function capitalizeFirstLetter(string) {
  if (string === null || string === undefined) return "Site";
  string = string.slice(1);
  return string
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export default {
  components: {
    ComingSoonCard,
  },
  data() {
    return {
      currentRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.currentRoute = to.path;
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.currentRoute = to.path;
    next();
  },
  computed: {
    siteName() {
      const name = capitalizeFirstLetter(this.currentRoute);
      if (name === "Staking") return "Staking + Leveling";
      return name;
    },
    title() {
      if (this.currentRoute === "/mint" || this.currentRoute === "/game") {
        return "Coming Soon";
      } else {
        return "Upon Completion of Initial Mint";
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/mt.scss";
</style>
