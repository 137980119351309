<template>
  <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
    <div class="card cardShadowOnHover" ref="containerImgFacy">
      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          A Few Example NFTs
        </h3>
      </div>
      <div class="container">
        <div class="box-a">
          <div class="overlay"></div>
          <div class="image"></div>
        </div>
        <div class="box-b">
          <div class="overlay"></div>
          <div class="image"></div>
        </div>
        <div class="box-c">
          <div class="overlay"></div>
          <div class="image"></div>
        </div>
        <div class="box-d">
          <div class="overlay"></div>
          <div class="image"></div>
        </div>
        <div class="box-e">
          <div class="overlay"></div>
          <div class="image"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.1,
      description: "Height left visible to start fadeout",
    },
  },

  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop =
        this.$refs.containerImgFacy.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.containerImgFacy.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.05;
  transition: opacity 5s ease;
}

.container {
  // --bg-color: #000;
  --bg-color: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 0 10px 0;
  position: relative;

  [class^="box-"] {
    border-bottom: 8px solid var(--bg-color);
    border-left: 4px solid var(--bg-color);
    border-right: 4px solid var(--bg-color);
    border-top: 8px solid var(--bg-color);
    flex: 1;
    height: 600px;
    width: 600px;
    position: relative;
    text-align: center;
    transition: 200ms;

    &:first-child {
      border-left: 8px solid var(--bg-color);
    }

    &:last-child {
      border-right: 8px solid var(--bg-color);
    }

    &:hover {
      color: #fff;
      cursor: pointer;
      flex-grow: 4;
      transition: 500ms;
    }

    .image {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    .overlay {
      background-color: rgba(0, 0, 0, 0.6);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: 200ms;
      z-index: 2;
    }

    &:hover .overlay {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.image {
  overflow: hidden;
  height: 600px;
  position: relative;
}

.card-body {
  padding: 0;
}

.box-a .image {
  background-image: url(https://raw.githubusercontent.com/NftClubCash/MutantsPreview/master/8.png);
  background-position: center;
  background-size: 600px 600px;
}

.box-b .image {
  background-image: url(https://raw.githubusercontent.com/NftClubCash/MutantsPreview/master/2.png);
  background-position: bottom center;
  background-size: 600px 600px;
}

.box-c .image {
  background-image: url(https://raw.githubusercontent.com/NftClubCash/MutantsPreview/master/10.png);
  background-position: center;
  background-size: 600px 600px;
}

.box-d .image {
  background-image: url(https://raw.githubusercontent.com/NftClubCash/MutantsPreview/master/9.png);
  background-position: center;
  background-size: 600px 600px;
}

.box-e .image {
  background-image: url(https://raw.githubusercontent.com/NftClubCash/MutantsPreview/master/5.png);
  background-position: center;
  background-size: 600px 600px;
}
</style>
