 <template>
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <!-- NAMING PORTAL CARD -->

      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          Naming Portal
        </h3>
      </div>

      <div class="card-body">
        <p>
          The "Naming Portal" feature allows NFT holders to change the name of
          their NFTs as they appear in the game and on supported marketplaces.
          To do this, the NFT must be in the player's wallet and they must pay a
          small fee. This feature adds a personal touch to the NFTs, allowing
          players to choose names like "The Destroyer" or "Merchant of Doom"
          instead of generic names like "3257" or "1890."
        </p>
      </div>
      <!-- NAMING PORTAL CARD -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  components: {},

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}
</style>
