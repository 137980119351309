import { render, staticRenderFns } from "./FinalSayCard.vue?vue&type=template&id=39309594&scoped=true&"
import script from "./FinalSayCard.vue?vue&type=script&lang=js&"
export * from "./FinalSayCard.vue?vue&type=script&lang=js&"
import style0 from "./FinalSayCard.vue?vue&type=style&index=0&id=39309594&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39309594",
  null
  
)

export default component.exports