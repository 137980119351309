<template>
  <!-- DISCLAIMER CARD -->
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          Welcome to
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars Project on Shibarium
        </h3>
      </div>

      <ImgFadeInOut class="imgShadowOnHover" alt="Mutant Wars Logo" />

      <div class="card-body">
        <h2 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          Disclaimer
        </h2>

        <blockquote>
          <p class="blockquote blockquote-primary">
            'The information shared on this website is not all-encompassing or
            comprehensive and does not in any way intend to create or put into
            implicit effect any elements of a contractual relationship. The
            primary purpose of this website is to provide potential investors
            with pertinent information in order for them to thoroughly analyse
            the project and make an informed decision. Prior to using or
            investing in the Mutant Wars NFTs and ecosystem, we strongly
            advocate a careful study of the Project. Certain statements,
            estimates and information featured on this website are to be taken
            as variable, whilst everything will be done to achieve the goals and
            be as close to what is laid out on the website. Should unforeseen
            circumstances and issues come in the way, the project will adapt and
            change to overcome those challenges whilst trying to stay true to
            the original goal and continue to grow.'
            <br />
            <br />
            <small> - Diggle, Project Owner </small>
          </p>
        </blockquote>
      </div>
    </div>
  </div>
  <!-- DISCLAIMER CARD  -->
</template>

<script>
import ImgFadeInOut from "@/components/ImgFadeInOut.vue";

export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  components: {
    ImgFadeInOut,
  },

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}
</style>
