 <template>
  <div class="card cardShadowOnHover" ref="container">
    <div :class="{ 'fade-in': isVisible, 'fade-out': !isVisible }">
      <!-- DEV RUNNING ORDER CARD -->
      <div class="card-header mb-2">
        <h5 class="card-category animatedTextOnHover animatedTextShadowOnHover">
          The Mutants Wars
        </h5>
        <h3 class="card-title animatedTextOnHover animatedTextShadowOnHover">
          Development Running Order
        </h3>
      </div>

      <div class="card-body">
        <p>
          Rather than have a Generic roadmap that is Rarely fulfilled or
          delivered on what will be provided is a provisional initial
          Development Run list, this may be subject to some changes,
          developments will be completed as soon as possible but no specific
          timeline will be given until solid release dates are 100% able to be
          Hit.
        </p>
        <ul>
          <li class="animatedTextOnHover animatedTextShadowOnHover">Staking</li>
          <li class="animatedTextOnHover animatedTextShadowOnHover">
            Levelling
          </li>
          <li class="animatedTextOnHover animatedTextShadowOnHover">
            Liquidity Incentive Pools
          </li>
          <li class="animatedTextOnHover animatedTextShadowOnHover">PVP V1</li>
          <li class="animatedTextOnHover animatedTextShadowOnHover">
            Naming Portal
          </li>
          <li class="animatedTextOnHover animatedTextShadowOnHover">
            PVP V2 + 2K mint
          </li>
          <li class="animatedTextOnHover animatedTextShadowOnHover">
            Battle the Bank
          </li>
          <li class="animatedTextOnHover animatedTextShadowOnHover">
            PVP V3 + 2K mint
          </li>
        </ul>

        <p>
          Upon completion of these, further developments will be undertaken to
          carry on adding value to the Ecosystem and to innovate. Whilst there
          are a lot of directions that can be taken due to the size and scope of
          the Collection, each further development will be scrutinized to ensure
          its financially viable, makes sense for the vision of the community
          and is something that the community would enjoy.
        </p>
      </div>
      <!-- DEV RUNNING ORDER CARD -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heightVisibleToFadeOut: {
      type: Number,
      default: 0.25,
      description: "Height left visible to start fadeout",
    },
  },

  components: {},

  data() {
    return {
      isVisible: false,
      imageSrc: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const containerTop = this.$refs.container.getBoundingClientRect().top;
      const containerBottom =
        this.$refs.container.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      const height = containerBottom - containerTop;

      if (
        containerTop < windowHeight - this.heightVisibleToFadeOut * height &&
        containerBottom > this.heightVisibleToFadeOut * height
      ) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-out-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 5s ease;
}

.fade-out {
  opacity: 0.1;
  transition: opacity 5s ease;
}
</style>
