<template>
  <div class="battle">
    <div class="team team-1">
      <h2>Team 1</h2>
      <div class="team-members">
        <div class="team-member" v-for="(nft, index) in team1" :key="index">
          <img :src="nft.image" class="nft-image" />
          <div class="nft-name">{{ nft.name }}</div>
          <div class="nft-healthbar">
            <div
              class="nft-healthbar-progress"
              :style="{ width: nft.health + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="team team-2">
      <h2>Team 2</h2>
      <div class="team-members">
        <div class="team-member" v-for="(nft, index) in team2" :key="index">
          <img :src="nft.image" class="nft-image" />
          <div class="nft-name">{{ nft.name }}</div>
          <div class="nft-healthbar">
            <div
              class="nft-healthbar-progress"
              :style="{ width: nft.health + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="battlefield">
      <div class="battle-status" v-if="battleStatus">
        <div>{{ battleStatus }}</div>
        <button @click="resetBattle">New Battle</button>
      </div>
      <div class="attack-buttons" v-if="!battleStatus">
        <button
          v-for="(nft, index) in team1"
          :key="index"
          @click="attack(nft, team2[0])"
        >
          {{ nft.name }} Attack
        </button>
        <button
          v-for="(nft, index) in team2"
          :key="index"
          @click="attack(nft, team1[0])"
        >
          {{ nft.name }} Attack
        </button>
      </div>
    </div>
  </div>
</template>

<!-- 
This code defines an attack() function to simulate an attack between two NFTs, a battle() function to simulate a battle between two NFTs, and a startBattle() function to start a battle between two teams of NFTs. The startBattle() function iterates through the NFTs in each team and calls the battle() function to simulate a battle between each pair of NFTs. The results of each battle are stored in the battleLog array, which can be displayed to the user to visualize the battles. -->

<script>
export default {
  props: {
    team1: {
      type: Array,
      required: true,
    },
    team2: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      battleStatus: null,
    };
  },
  methods: {
    attack(attacker, defender) {
      // Calculate damage and update defender's health
      const damage = Math.floor(attacker.power * Math.random());
      defender.health -= damage;

      // Check if defender is defeated
      if (defender.health <= 0) {
        defender.health = 0;
        this.battleStatus = `${attacker.name} has defeated ${defender.name}!`;
      } else {
        this.battleStatus = `${attacker.name} has dealt ${damage} damage to ${defender.name}.`;
      }

      // Check if all members of a team are defeated
      const team1Defeated = this.team1.every((nft) => nft.health <= 0);
      const team2Defeated = this.team2.every((nft) => nft.health <= 0);
      if (team1Defeated) {
        this.battleStatus = "Team 2 has won the battle!";
      } else if (team2Defeated) {
        this.battleStatus = "Team 1 has won the battle!";
      }
    },

    resetBattle() {
      // Reset health for all NFTs
      this.team1.forEach((nft) => {
        nft.health = 100;
      });
      this.team2.forEach((nft) => {
        nft.health = 100;
      });

      // Reset battle status
      this.battleStatus = {
        started: false,
        winner: null,
        rounds: [],
      };
    },

    startBattle() {
      // Ensure both teams have been selected
      if (this.team1.length === 0 || this.team2.length === 0) {
        alert("Please select both teams before starting the battle.");
        return;
      }

      // Reset battle status
      this.battleStatus = {
        started: true,
        winner: null,
        rounds: [],
      };

      // Loop through rounds until one team is defeated
      while (this.battleStatus.winner === null) {
        let roundResults = {
          team1Attacks: [],
          team2Attacks: [],
          team1Damages: [],
          team2Damages: [],
        };

        // Team 1 attacks
        this.team1.forEach((attacker) => {
          // Select a random defender from Team 2
          let defender =
            this.team2[Math.floor(Math.random() * this.team2.length)];
          // Calculate damage
          let damage = this.calculateDamage(attacker, defender);
          // Update defender's health
          defender.health -= damage;
          if (defender.health < 0) {
            defender.health = 0;
          }
          // Log attack and damage
          roundResults.team1Attacks.push(
            attacker.name +
              " attacked " +
              defender.name +
              " for " +
              damage +
              " damage."
          );
          roundResults.team2Damages.push(
            defender.name +
              " took " +
              damage +
              " damage and has " +
              defender.health +
              " health remaining."
          );
        });

        // Team 2 attacks
        this.team2.forEach((attacker) => {
          // Select a random defender from Team 1
          let defender =
            this.team1[Math.floor(Math.random() * this.team1.length)];
          // Calculate damage
          let damage = this.calculateDamage(attacker, defender);
          // Update defender's health
          defender.health -= damage;
          if (defender.health < 0) {
            defender.health = 0;
          }
          // Log attack and damage
          roundResults.team2Attacks.push(
            attacker.name +
              " attacked " +
              defender.name +
              " for " +
              damage +
              " damage."
          );
          roundResults.team1Damages.push(
            defender.name +
              " took " +
              damage +
              " damage and has " +
              defender.health +
              " health remaining."
          );
        });

        // Add round results to battle status
        this.battleStatus.rounds.push(roundResults);

        // Check if either team has been defeated
        if (this.team1.every((nft) => nft.health === 0)) {
          this.battleStatus.winner = "Team 2";
        } else if (this.team2.every((nft) => nft.health === 0)) {
          this.battleStatus.winner = "Team 1";
        }
      }
    },

    calculateDamage(attacker, defender) {
      // Calculate damage based on attacker's attack stat and defender's defense stat
      let damage = attacker.attack - defender.defense;
      // Ensure damage is at least 1
      if (damage < 1) {
        damage = 1;
      }
      return damage;
    },

    // Function to simulate an attack
    attack(attacker, defender) {
      let damage = this.calculateDamage(attacker.attack, defender.defense);
      defender.health -= damage;
      if (defender.health < 0) {
        defender.health = 0;
      }
      return damage;
    },

    // Function to simulate a battle between two NFTs
    battle(nft1, nft2) {
      let log = [];
      while (nft1.health > 0 && nft2.health > 0) {
        // NFT 1 attacks
        let damage = this.attack(nft1, nft2);
        log.push(
          `${nft1.name} (${nft1.health} HP) attacks ${nft2.name} for ${damage} damage (${nft2.health} HP left)`
        );

        // NFT 2 attacks
        if (nft2.health > 0) {
          damage = this.attack(nft2, nft1);
          log.push(
            `${nft2.name} (${nft2.health} HP) attacks ${nft1.name} for ${damage} damage (${nft1.health} HP left)`
          );
        }
      }

      // Determine winner and loser
      let winner, loser;
      if (nft1.health > 0) {
        winner = nft1;
        loser = nft2;
      } else {
        winner = nft2;
        loser = nft1;
      }

      // Add end of battle log message
      log.push(
        `${winner.name} (${winner.health} HP) wins against ${loser.name} (${loser.health} HP)`
      );

      // Return log of battle
      return log;
    },

    // Function to start a battle between two teams
    startBattle() {
      this.battleLog = [];
      for (let i = 0; i < this.team1.length; i++) {
        let nft1 = this.team1[i];
        let nft2 = this.team2[i];
        let log = this.battle(nft1, nft2);
        this.battleLog.push(log);
      }
    },
  },
};
</script>

<style>
.nft-list {
  display: flex;
  flex-wrap: wrap;
}

.nft-item {
  width: 200px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.nft-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.nft-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.nft-stat {
  display: flex;
  align-items: center;
}

.nft-stat-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.nft-selected {
  border-color: green;
}
</style>

<!-- To implement animations for the game visualization, you can use CSS animations and JavaScript to trigger them at the appropriate times. Here's an example of how you can add an animation for an attack:

Create a CSS animation for the attack: -->

<!-- .attack {
  animation: attack 1s ease-out;
}

@keyframes attack {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
} -->
<!-- 
This animation will move the NFT up by 50% of its height and then back down to its original position over the course of 1 second.

Add the "attack" class to the NFT element when it is attacked: -->

<!-- // Trigger attack animation
nftElement.classList.add('attack');

// Remove attack class after animation completes
setTimeout(() => {
  nftElement.classList.remove('attack');
}, 1000); // Wait 1 second for animation to complete -->
<!-- 
This code will add the "attack" class to the NFT element, triggering the animation. It will then remove the "attack" class after 1 second, allowing the NFT to return to its original position.

For fire and explosion animations, you can create similar CSS animations and trigger them in a similar way using JavaScript. You can also use CSS sprites or GIFs to display the fire and explosion animations.
Here's an example of a CSS animation for a fire effect: -->

<!-- .fire {
  animation: fire 0.5s steps(7) forwards;
  background-image: url('fire.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
}

@keyframes fire {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
} -->
<!-- 
This animation will display a sequence of 7 frames from the "fire.png" image over the course of 0.5 seconds. The "forwards" keyword will make sure that the final frame is displayed after the animation completes.

You can add the "fire" class to an NFT element to display the fire animation. To remove the fire animation, you can simply remove the "fire" class from the element.
 -->
